<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Card>
        <template #header></template>
        <template #content>
          <div class="p-col-12 p-px-3">
            <Message v-if="isFormSubmitted" severity="success" :sticky="true" :closable="false"
              >Successfully submitted</Message
            >
          </div>

          <div class="p-formgrid p-grid p-fluid p-p-3">
            <LabeledField
              label="Partner (or division)"
              class="p-col-12"
              :autoLayout="false"
              :show-help="v.divisionId.$error || !formData.divisionId?.id"
              :errors="v.divisionId.$errors"
            >
              <AutoComplete
                placeholder="Search by Name, Abbreviation, or Partner ID"
                field="name"
                v-model="formData.division"
                :delay="500"
                :disabled="isFormSubmitted"
                :suggestions="divisions"
                @complete="searchDivisions"
                dropdown
                forceSelection
              />
            </LabeledField>
            <LabeledField
              label="Teammates"
              class="p-col-12"
              :autoLayout="false"
              :show-help="v.teammateIds.$error"
              :errors="v.teammateIds.$errors"
            >
              <AutoComplete
                placeholder="Search by Email, Name, or Employee ID"
                field="name"
                v-model="formData.teammates"
                :delay="500"
                :disabled="isFormSubmitted"
                :suggestions="teammates"
                @complete="searchTeammates"
                forceSelection
                dropdown
                multiple
              />
            </LabeledField>
            <LabeledField
              label="Amount"
              class="p-col-12"
              :autoLayout="false"
              :show-help="v.amount.$error"
              :errors="v.amount.$errors"
            >
              <InputNumber
                inputId="currency-us"
                v-model="formData.amount"
                mode="currency"
                currency="USD"
                locale="en-US"
              />
            </LabeledField>
            <LabeledField
              label="Detailed Message"
              class="p-col-12"
              :autoLayout="false"
              :show-help="v.priorityRequest.$error"
              :errors="v.priorityRequest.$errors"
            >
              <Textarea
                placeholder="Please provide as much detail as possible."
                v-model="formData.priorityRequest"
                :disabled="isFormSubmitted"
                :autoResize="true"
                rows="4"
              />
            </LabeledField>
          </div>
        </template>
        <template #footer>
          <div class="p-d-flex p-jc-end p-px-3 p-pb-3">
            <Button :label="isFormSubmitted ? 'Submit Another' : 'Submit'" :loading="loading" @click="handleSubmit" />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import useIntercom from '../../hooks/useIntercom'
import { computed } from '@vue/reactivity'

const store = useStore()
const { fetchFieldOptions, submitHelpBKEntry } = useIntercom()

// eslint-disable-next-line no-unused-vars
const intercomUserId = computed(() => store.state.user?.employeeId)

const formData = reactive({
  division: null,
  teammates: null,
  priorityRequest: null,
  divisionId: null,
  teammateIds: null,
  amount: null
})

const divisions = ref(null)
const teammates = ref(null)
const loading = ref(false)
const isFormSubmitted = ref(false)

watch(
  () => formData.division,
  (value) => (formData.divisionId = value?.id)
)

watch(
  () => formData.teammates,
  (value) => (formData.teammateIds = value?.map((el) => el.id))
)

const v = useVuelidate(
  {
    divisionId: { required },
    teammateIds: { required },
    amount: { required },
    priorityRequest: { required }
  },
  formData
)

const searchDivisions = async (event) => {
  const resp = await fetchFieldOptions('division', event.query)
  divisions.value = resp.data
}

const searchTeammates = async (event) => {
  const resp = await fetchFieldOptions('teammate', event.query)
  teammates.value = resp.data
}

const handleSubmit = async () => {
  if (isFormSubmitted.value) {
    v.value.$reset()
    Object.keys(formData).forEach((key) => (formData[key] = null))
    isFormSubmitted.value = false
    return
  }

  v.value.$touch()
  const data = {
    division_id: formData.divisionId,
    teammate_ids: formData.teammateIds,
    amount: formData.amount,
    priority_request: formData.priorityRequest
  }

  if (v.value.$error) return
  loading.value = true
  await submitHelpBKEntry(data)
  loading.value = false
  isFormSubmitted.value = true
}

onMounted(() => window.Intercom('hide'))
</script>

<style scoped></style>
